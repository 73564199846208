import { render, staticRenderFns } from "./Attribute.vue?vue&type=template&id=62a2e95c&"
import script from "./Attribute.vue?vue&type=script&lang=js&"
export * from "./Attribute.vue?vue&type=script&lang=js&"
import style0 from "./Attribute.vue?vue&type=style&index=0&id=62a2e95c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports